<template>
    <div class="story-card" @click="onClick" :title="storyData.title">
        <div class="story-card__cover" :style="{backgroundImage: cover}"/>
        <svg v-if="!storyData.last_chapter_id"
             xmlns="http://www.w3.org/2000/svg"
             width="60" height="60"
             v-b-tooltip.hover title="Не установлена последняя глава"
             class="story-card__wip-label">
            <path
                d="M 12.939842,42.725977 L 16.932878,46.909148 L 23.397756,42.535812 L 28.531651,44.817580 L 30.242945,52.311968 L 35.757108,52.261322 L 37.278271,44.571699 L 42.412159,42.535812 L 48.877042,46.719018 L 51.919353,42.535812 L 44.582498,35.611888 C 25.777448,52.859892 9.5269875,26.276489 25.679494,15.155087 L 25.679473,11.162050 L 23.778048,12.112769 L 17.883580,8.3655608 L 13.811784,11.709383 L 17.503295,18.007224 L 15.411715,23.141112 L 8.2084628,24.337688 L 8.1862343,29.796158 L 15.601868,31.697601 L 17.645913,36.688908 L 12.939842,42.725977 z "
                id="path705"
                style="fill:#d70000;fill-rule:evenodd;stroke:#333333;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                transform="matrix(1.247113,0.000000,0.000000,1.247113,-7.239804,-7.665841)"/>
        </svg>
        <div class="story-card__content d-flex">
            <div class="story-card__title story-card__item story-card__item--big">{{ storyData.title }} ({{ storyData.id }})
            </div>
            <div class="story-card__publish-date story-card__item">{{ storyData.publish_date }}</div>
        </div>
        <div v-if="!storyData.is_published" class="story-card__hidden-label">Скрыта</div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: {
        story: Object
    },
    data: () => ({
        storyData: Object,
        categoriesData: Object,
        genresData: Object
    }),
    computed: {
        ...mapGetters('firebase', ['categories', 'getStoryById']),
        cover() {
            return undefined;
        },
    },
    created() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.updateStoryData)
            .$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.updateStoryData);
        this.storyData = this.getStoryById(this.story.id);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.updateStoryData);
    },
    methods: {
        onClick() {
            this.$emit('clicked', this.story.id);
        },
        updateStoryData() {
            this.storyData = this.getStoryById(this.story.id);
        }
    }
};
</script>

<style lang="scss">
.story-card {
    width: 176px;
    height: 287px;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 16px;
    border-radius: 16px;
    float: left;
    position: relative;
    cursor: pointer;
    
    &__wip-label {
        position: absolute;
        top: 4px;
        left: 0;
        transform: scale(.75);
    }
    
    &__cover {
        width: 100%;
        height: 220px;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        
        &:after {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, .2) 0%, #000 100%);
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
    &__content {
        position: relative;
        top: 228px;
        width: 100%;
        height: auto;
        padding: 0;
        flex-direction: column;
        color: #fff;
        align-items: flex-start;
        justify-content: flex-end;
    }
    
    &__item {
        font-size: 12px;
        font-weight: 400;
        color: #2D4C70;
    }
    
    &__title {
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
    }
    
    &__characteristics {
        font-weight: 400;
        display: flex;
    }
    
    &__hidden-label {
        background: #fff;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 16px;
        padding: 9px 16px;
        border-radius: 24px;
    }
}
</style>
